import React, { Component } from 'react';
import { connect } from 'react-redux';

import ActualTable from 'account/components/AppTokensTable/AppTokensTable';
import { AppTokenType } from 'account/components/AppTokensTable/types';
import { currentUserHasRight } from 'common/current_user';
import { ProfileState } from 'account/view/types';
import DomainRights from 'common/types/domainRights';

import * as Actions from '../actions';
import * as Selectors from '../selectors';

interface StateProps {
  appTokens: AppTokenType[];
  loadingData: boolean;
  viewingOwnProfile: boolean;
}

interface DispatchProps {
  /* eslint @typescript-eslint/ban-types: "warn" */
  loadAppTokens: Function;
}

type Props = StateProps & DispatchProps;

export class AppTokensTable extends Component<Props, ProfileState> {
  componentDidMount(): void {
    const { loadAppTokens } = this.props;
    loadAppTokens();
  }

  render() {
    const { appTokens, loadingData, viewingOwnProfile } = this.props;
    if (!currentUserHasRight(DomainRights.manage_users) || viewingOwnProfile) {
      return null;
    }

    return (
      <div id="app-tokens-table">
        <h3 id="app-tokens-table-title">App Tokens</h3>
        <ActualTable appTokens={appTokens} loadingData={loadingData} showTokens={false} />
      </div>
    );
  }
}

const mapStateToProps = (state: ProfileState): StateProps => ({
  appTokens: Selectors.getAppTokens(state),
  loadingData: Selectors.getAppTokensLoadingData(state),
  viewingOwnProfile: Selectors.getViewingOwnProfile(state)
});

const mapDispatchToProps: DispatchProps = {
  loadAppTokens: Actions.loadAppTokens
};

export default connect(mapStateToProps, mapDispatchToProps)(AppTokensTable);
