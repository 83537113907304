import type { AppTokensState, ProfileState, TeamState } from 'account/view/types';
import type { AppTokenType } from 'account/components/AppTokensTable/types';
import type Team from 'common/types/users/teams';
import { SORT_DIRECTION, SORT_KEYS } from 'common/teams-api';
import { makeZeroBasedPageFromPager } from 'account/view/utils';
import * as Selectors from './selectors';
import * as Actions from './actions';

/** FOR TEAMS **/
export const initialTeamState: TeamState = {
  orderBy: SORT_KEYS.SCREEN_NAME,
  resultCount: 0,
  searchQuery: '',
  searchResultCount: undefined,
  sortDirection: SORT_DIRECTION.ASC,
  teams: [],
  teamsResultLimit: 10,
  zeroBasedPage: 0,
  ui: {
    loadingData: true
  }
};

const handleColumnSort = (state: ProfileState, { columnKey }: { columnKey: string }): ProfileState => {
  const orderBy: string = Selectors.getTeamsOrderBy(state);
  const sortDirection: string = Selectors.getTeamsSortDirection(state);
  const typedSortDirection: string =
    sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
  if (orderBy !== columnKey) {
    return {
      ...state,
      teams: {
        ...state.teams,
        orderBy: columnKey,
        sortDirection: initialTeamState.sortDirection
      }
    };
  } else {
    return {
      ...state,
      teams: {
        ...state.teams,
        sortDirection: typedSortDirection
      }
    };
  }
};

const handleGotoPage = (state: ProfileState, { page }: { page: number }) => ({
  ...state,
  teams: {
    ...state.teams,
    zeroBasedPage: makeZeroBasedPageFromPager(page)
  }
});

const handleLoadTeamsSuccess = (
  state: ProfileState,
  { teams, resultCount }: { teams: Team[]; resultCount: number }
): ProfileState => ({
  ...state,
  teams: {
    ...state.teams,
    teams,
    resultCount,
    ui: {
      loadingData: false
    }
  }
});

const handleLoadTeamsFailure = (state: ProfileState, { error }: { error: string }): ProfileState => ({
  ...state,
  teams: {
    ...state.teams,
    ui: {
      loadingData: false
    }
  }
});

const handleTeamsSearch = (state: ProfileState, { query }: { query: string }): ProfileState => ({
  ...state,
  teams: {
    ...state.teams,
    searchQuery: query,
    ui: {
      loadingData: true
    }
  }
});

const handleTeamsSearchSuccess = (
  state: ProfileState,
  { teams, resultCount }: { teams: Team[]; resultCount: number }
): ProfileState => ({
  ...state,
  teams: {
    ...state.teams,
    teams,
    searchResultCount: resultCount,
    ui: {
      loadingData: false
    }
  }
});

/** FOR APP TOKENS **/
export const initialAppTokensState: AppTokensState = {
  appTokens: [],
  ui: {
    loadingData: true
  }
};

const handleLoadAppTokensSuccess = (
  state: ProfileState,
  { appTokens }: { appTokens: AppTokenType[] }
): ProfileState => ({
  ...state,
  appTokens: {
    appTokens,
    ui: {
      loadingData: false
    }
  }
});

const handleLoadAppTokensFailure = (state: ProfileState, { error }: { error: string }): ProfileState => ({
  ...state,
  appTokens: {
    ...state.appTokens,
    ui: {
      loadingData: false
    }
  }
});

/** FOR ROLES */
const handleRoleFetchSuccess = (
  state: ProfileState,
  { payload: { role } }: Actions.FetchRoleSuccess
): ProfileState => ({
  ...state,
  userRole: role
});

const handleRightsListFetchSuccess = (
  state: ProfileState,
  { payload: { rights } }: Actions.FetchRightsSuccess
): ProfileState => ({
  ...state,
  rightsCategories: rights
});

/** GENERAL STUFF **/
const reducer = (state: ProfileState, action: { type: string; payload: any }): ProfileState => {
  switch (action.type) {
    case Actions.GOTO_TEAM_PAGE:
      return handleGotoPage(state, action.payload);
    case Actions.LOAD_APP_TOKENS_SUCCESS:
      return handleLoadAppTokensSuccess(state, action.payload);
    case Actions.LOAD_APP_TOKENS_FAILURE:
      return handleLoadAppTokensFailure(state, action.payload);
    case Actions.LOAD_TEAMS_FAILURE:
      return handleLoadTeamsFailure(state, action.payload);
    case Actions.LOAD_TEAMS_SUCCESS:
      return handleLoadTeamsSuccess(state, action.payload);
    case Actions.TEAMS_SEARCH:
      return handleTeamsSearch(state, action.payload);
    case Actions.TEAMS_SEARCH_SUCCESS:
      return handleTeamsSearchSuccess(state, action.payload);
    case Actions.SORT_TEAM_COLUMN:
      return handleColumnSort(state, action.payload);
    case Actions.FETCH_ROLE_SUCCESS:
      return handleRoleFetchSuccess(state, action as Actions.FetchRoleSuccess);
    case Actions.FETCH_RIGHTS_SUCCESS:
      return handleRightsListFetchSuccess(state, action as Actions.FetchRightsSuccess);
    default:
      return state;
  }
};

export default reducer;
