import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import AssetBrowser from 'common/components/AssetBrowser';
import * as assetBrowserConstants from 'common/components/AssetBrowser/lib/constants';
import { currentUserHasRight } from 'common/current_user';
import type InteractiveUser from 'common/types/users/interactiveUser';
import DomainRights from 'common/types/domainRights';
import * as ceteraHelpers from 'common/components/AssetBrowser/lib/helpers/cetera';
import { FeatureFlags } from 'common/feature_flags';

import { ProfileState } from 'account/view/types';
import { getUser, getViewingOwnProfile } from 'account/view/selectors';

interface Props {
  user: InteractiveUser;
  viewingOwnProfile: boolean;
}

/**
 * Renders an asset browser tailored to looking at a user's profile. Note that the user will not always be
 * the current user!
 */
export class UserProfileAssetBrowser extends Component<Props> {
  tabs: { [p: string]: { props: { baseFilters: { forUser: string } | { sharedTo: string[] } | any } } };
  columns: string[];
  viewingOwnProfile: boolean;

  constructor(props: Props) {
    super(props);

    const { user, viewingOwnProfile } = props;
    const { id: userId } = user;

    this.tabs = {
      [assetBrowserConstants.TAB_MY_ASSETS]: {
        props: {
          baseFilters: {
            forUser: userId
          }
        }
      }
    };

    const forUser = ceteraHelpers.getProfileUserTeams();
    if (FeatureFlags.value('enable_teams') && !isEmpty(forUser)) {
      this.tabs[assetBrowserConstants.TAB_MY_TEAM_ASSETS] = { props: { baseFilters: {} } };
    }

    // If the current user is an admin, they can also see assets shared to the user
    // If the user is viewing their own profile, they can see assets shared to themselves as well
    // TODO: EN-29471: Do we need any of the admin checks?
    if (
      (currentUserHasRight(DomainRights.manage_users) &&
        currentUserHasRight(DomainRights.edit_others_datasets)) ||
      viewingOwnProfile
    ) {
      // we want to show this user and all of their teams
      const sharedTo = [...(user.teams || []).map((team) => team.id), userId];

      this.tabs[assetBrowserConstants.TAB_SHARED_TO_ME] = {
        props: {
          baseFilters: {
            sharedTo
          }
        }
      };
    }

    this.columns = [
      assetBrowserConstants.COLUMN_TYPE,
      assetBrowserConstants.COLUMN_NAME,
      assetBrowserConstants.COLUMN_ACTIONS,
      assetBrowserConstants.COLUMN_LAST_UPDATED_DATE,
      assetBrowserConstants.COLUMN_CATEGORY,
      assetBrowserConstants.COLUMN_OWNER,
      assetBrowserConstants.COLUMN_AUDIENCE
    ];

    this.viewingOwnProfile = viewingOwnProfile;
  }

  render() {
    return (
      <AssetBrowser
        columns={this.columns}
        tabs={this.tabs}
        initialTab={assetBrowserConstants.TAB_MY_ASSETS}
        pageSize={5}
        enableAssetInventoryLink={false}
        showAssetCounts={true}
        showFilters={true}
        showHeader={true}
        showManageAssets={true}
        showSearchField={true}
        viewingOwnProfile={this.viewingOwnProfile}
      />
    );
  }
}

const mapStateToProps = (state: ProfileState): Props => ({
  user: getUser(state),
  viewingOwnProfile: getViewingOwnProfile(state)
});

export default connect(mapStateToProps)(UserProfileAssetBrowser);
