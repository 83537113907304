import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import I18n from 'common/i18n';
import DefaultProfileImage from 'common/components/DefaultProfileImage';
import { getId, getUserHasImage, getViewingOwnProfile } from 'account/view/selectors';
import { getUserImgUrl, getProfileEditUrl } from 'account/view/utils';

import UserProfileInfo from './UserProfileInfo';

/**
 * Renders the header for the user's profile page based on whether the current user is viewing their own profile or another user's profile.
 * @param viewingOwnProfile Whether or not the user viewing this profile is also the user who is logged in
 * @param uid UID (4x4) of the user
 */
export const renderProfileHeader = (viewingOwnProfile: boolean, uid: string) => {
  const header_text = viewingOwnProfile ? 'your_profile' : 'profile';
  let response = <h1 className="profile-head-title">{I18n.t('account.common.profile.' + header_text)}</h1>;

  if (viewingOwnProfile) {
    response = (
      <h1 className="profile-head-title">
        <a
          className="profile-edit-link"
          href={getProfileEditUrl(uid)}
          title={I18n.t('account.common.profile.edit_link')}
        >
          <span>{I18n.t('account.common.profile.' + header_text)}</span>
          <span className="socrata-icon-edit" />
        </a>
      </h1>
    );
  }

  return response;
};

/**
 * Renders a user's profile image or, if they haven't uploaded one, the default profile image.
 * @param userHasImage Whether or not the user has an uploaded profile image
 * @param uid UID (4x4) of the user
 */
export const renderProfileImage = (userHasImage: boolean, uid: string) => {
  if (userHasImage) {
    return <img src={getUserImgUrl(uid)} alt={I18n.t('account.common.profile.img_alt_text')} />;
  }

  return <DefaultProfileImage />;
};

/**
 * Contains metadata about the user who's profile is currently being viewed.
 *
 * This includes their profile picture, display name, and other info they've entered.
 *
 * If you're looking at your own profile, you'll also see a button to go to the edit experience.
 * If you're a site admin, you'll see the user's current role and a button to open a modal to view the role's rights.
 *
 * @see UserProfileInfo
 * @see UserProfileRole (instantiated within UserProfileInfo)
 */
const UserProfile: FunctionComponent = () => {
  const uid = useSelector(getId);
  const userHasImage = useSelector(getUserHasImage);
  const viewingOwnProfile = useSelector(getViewingOwnProfile);

  return (
    <div className="profile-container">
      <div className="profile-head-row">{renderProfileHeader(viewingOwnProfile, uid)}</div>
      <div className="profile-head-row">
        <div className="profile-info-and-image-container">
          {renderProfileImage(userHasImage, uid)}
          <UserProfileInfo />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
