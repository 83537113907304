import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import createStore, { getGlobalUsersInfo } from './createStore';
import ProfileWrapper from 'account/view/Wrapper';
import UserProfile from 'account/view/UserProfile';
import UserProfileAssetBrowser from 'account/view/UserProfileAssetBrowser';
import UserTeamsTable from 'account/view/teams/UserTeamsTable';
import AppTokensTable from 'account/view/appTokens/AppTokensTable';

import './styles.scss';

const rootNode = document.querySelector('#view-account');

// Don't refetch and rerender the component
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      staleTime: twentyFourHoursInMs
    }
  }
});

ReactDOM.render(
  <Provider store={createStore(getGlobalUsersInfo())}>
    <QueryClientProvider client={queryClient}>
      <ProfileWrapper>
        <UserProfile />
        <UserProfileAssetBrowser />
        <UserTeamsTable />
        <AppTokensTable />
      </ProfileWrapper>
    </QueryClientProvider>
  </Provider>,
  rootNode
);
