import React from 'react';
import { useQuery } from 'react-query';

import I18n from 'common/i18n';
import Alert, { AlertType } from 'common/components/Alert';

import fetchVerificationStatus, {
  queryConfig,
  checkIsUserVerified
} from 'account/edit/components/ChangeEmail/fetchEmailVerificationStatusUtil';

interface Props {
  children?: React.ReactNode;
}

const getBasePath = () => {
  const loc = document.location;
  const domain = loc.hostname;

  return `${loc.protocol}//${domain}`;
};

/**
 * Wrapper around the user's profile. Will display an alert when a pending email change is detected.
 */
const ProfileWrapper: React.FC<Props> = (props) => {
  const { data, isLoading } = useQuery(
    'check-email-status',
    async () => await fetchVerificationStatus(),
    queryConfig
  );

  return (
    <div className="wrapper">
      {!isLoading && !checkIsUserVerified(data) && (
        <Alert type={AlertType.Warning} style={{ marginBottom: '15px' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: I18n.t('account.common.profile.pending_email_change', {
                resend_link: `${getBasePath()}/profile/edit/settings`
              })
            }}
          />
        </Alert>
      )}
      {props.children}
    </div>
  );
};

export default ProfileWrapper;
