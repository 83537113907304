import { connect } from 'react-redux';
import PagerGroup from 'common/components/PagerGroup';

import * as Actions from '../actions';
import * as Selectors from '../selectors';
import { ProfileState } from 'account/view/types';

interface StateProps {
  currentPage: number;
  resultCount: number;
  resultsPerPage: number;
}

interface DispatchProps {
  /* eslint @typescript-eslint/ban-types: "warn" */
  onChangePage: Function;
}

const mapStateToProps = (state: ProfileState): StateProps => {
  return {
    currentPage: Selectors.getTeamsCurrentPage(state),
    resultCount: Selectors.getTeamsResultCount(state),
    resultsPerPage: Selectors.getTeamsResultsLimit(state)
  };
};

const mapDispatchToProps: DispatchProps = {
  onChangePage: Actions.gotoTeamPage
};
export default connect(mapStateToProps, mapDispatchToProps)(PagerGroup);
