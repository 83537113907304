import get from 'lodash/get';
import eq from 'lodash/eq';
import type { AppTokenType } from 'account/components/AppTokensTable/types';
import type { ProfileState } from 'account/view/types';
import type InteractiveUser from 'common/types/users/interactiveUser';
import type Team from 'common/types/users/teams';
import type { Role, RightsCategory } from '@socrata/core-roles-api';

export const getUser = (state: ProfileState): InteractiveUser => get(state, 'user');
export const getCurrentUser = (state: ProfileState): InteractiveUser => get(state, 'currentUser');
export const getUsername = (state: ProfileState): string => get(getUser(state), 'displayName', '');
export const getDescription = (state: ProfileState): string => get(getUser(state), 'description', '');
export const getDomain = (state: ProfileState): string => get(getUser(state), 'domain', '');
export const getOrganization = (state: ProfileState): string => get(getUser(state), 'organization', '');
export const getTitle = (state: ProfileState): string => get(getUser(state), 'title', '');
export const getId = (state: ProfileState): string => get(getUser(state), 'id', '');
export const getUserHasImage = (state: ProfileState): boolean =>
  Boolean(get(getUser(state), 'profileImageUrlLarge', false));
export const getViewingOwnProfile = (state: ProfileState): boolean =>
  eq(getUser(state).id, getCurrentUser(state).id);
export const getUserRole = (state: ProfileState): Role | undefined => get(state, 'userRole', undefined);
export const getRightCategories = (state: ProfileState): RightsCategory[] | undefined =>
  get(state, 'rightsCategories', undefined);

// For TeamsTable
export const getTeams = (state: ProfileState): Team[] => get(state, 'teams.teams', []);
export const getTeamsCurrentPage = (state: ProfileState): number => get(state, 'teams.zeroBasedPage', -1) + 1;
export const getTeamsLoadingData = (state: ProfileState): boolean => get(state, 'teams.ui.loadingData', true);
export const getTeamsSearchQuery = (state: ProfileState): string => get(state, 'searchQuery', undefined);
export const getTeamsResultsLimit = (state: ProfileState): number =>
  get(state, 'teams.teamsResultLimit', null);
export const getTeamsOrderBy = (state: ProfileState): string => get(state, 'teams.orderBy', '');
export const getTeamsOffset = (state: ProfileState): number =>
  getTeamsResultsLimit(state) * (getTeamsCurrentPage(state) - 1);
export const getTeamsSortDirection = (state: ProfileState): string => get(state, 'teams.sortDirection', '');
export const getTeamsResultCount = (state: ProfileState): number => get(state, 'teams.resultCount', 0);

// For AppTokensTable
export const getAppTokens = (state: ProfileState): AppTokenType[] => get(state, 'appTokens.appTokens', []);
export const getAppTokensLoadingData = (state: ProfileState): boolean =>
  get(state, 'appTokens.ui.loadingData', true);
