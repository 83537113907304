import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import UserProfileRole from 'account/view/UserProfileRole';
import I18n from 'common/i18n';
import { getUsername, getOrganization, getTitle, getDescription } from './selectors';

/**
 * Renders a user-content-info block
 * @param key the key for which info block is being rendered
 * @param value the data value (if one exists) from the user's record
 */
const renderUserContentInfoBlock = (key: string, value: string | JSX.Element) => {
  let response;

  if (value) {
    const classNames = 'user-content-info user-content-' + key.replace(/_/g, '-');
    response = (
      <div className={classNames}>
        <div className="user-content-info-label">{I18n.t('account.common.profile.' + key)}</div>
        <div className="user-content-info-value">{value}</div>
      </div>
    );
  }

  return response;
};

/**
 * Basic metadata to show on a user's profile
 *
 * I.e. their display name, description, and organization.
 */
const UserInfo: FunctionComponent = () => {
  const userDisplayName = useSelector(getUsername);
  const userOrganization = useSelector(getOrganization);
  const userTitle = useSelector(getTitle);
  const userDescription = useSelector(getDescription);

  return (
    <div className="user-content-container">
      <div className="user-content-info-container">
        {renderUserContentInfoBlock('display_name', userDisplayName)}
        {renderUserContentInfoBlock('organization', userOrganization)}
        {renderUserContentInfoBlock('title', userTitle)}
        <UserProfileRole />
      </div>
      <div className="user-content-description-container">
        {renderUserContentInfoBlock('description', userDescription)}
      </div>
    </div>
  );
};

export default UserInfo;
