import React, { useEffect, FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { Role, RightsCategory } from '@socrata/core-roles-api';

import I18n from 'common/i18n';
import { FeatureFlags } from 'common/feature_flags';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import Button from 'common/components/Button';
import { Modal, ModalContent, ModalHeader } from 'common/components/Modal';
import LocalizedRoleName from 'common/i18n/components/LocalizedRoleName';

import { getRightCategories } from './selectors';
import { fetchRights } from './actions';

interface UserProfileRoleRightsListModalProps {
  /** Role to show rights for */
  role: Role;

  /** Function to call to close modal */
  onClose: () => void;
}

/**
 * Renders a list of rights that a role has that are in a category.
 *
 * @param role Role of the user who we're looking at
 * @param category Rights category to render
 */
export const renderRightCategory = (role: Role, category: RightsCategory) => {
  // we only want to show the rights that are actually in the role
  const rightsInRole = category.rights.filter((right) => role.rights.includes(right.name));

  // the role has no rights in this category, skip it
  if (rightsInRole.length === 0) {
    return null;
  }

  return (
    <li key={category.translationKey} className="rights-category-right-list">
      {/* This are the same translation strings you'll see on /admin/roles (frontend/public/javascripts/adminRoles) */}
      {I18n.t(`roles.right_categories.${category.translationKey}.name`)}
      <ul>
        {rightsInRole
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((right) => (
            <li key={right.name} className="rights-category-right-list-right">
              {I18n.t(`roles.rights.${right.name}.name`)}
            </li>
          ))}
      </ul>
    </li>
  );
};

/**
 * Renders the rights that the given role has as a nested unordered list.
 *
 * @param role Role of the user who we're looking at
 * @param rights List of all right categories
 */
const renderRights = (role: Role, rights: RightsCategory[]) => (
  <ul>
    {rights.sort((a, b) => a.sortOrder - b.sortOrder).map((category) => renderRightCategory(role, category))}
  </ul>
);

/** Header for the modal, contains the role's name */
const renderHeader = (role: Role, onClose: () => void) => (
  <ModalHeader onDismiss={onClose}>
    <h2>
      <LocalizedRoleName role={role} /> {I18n.t('account.common.profile.role_capabilities')}
    </h2>
  </ModalHeader>
);

/** Footer for the modal, contains links to /admin/roles and /admin/users and a close button */
export const renderFooter = (onClose: () => void) => {
  // only show a link to /admin/roles if configurable_roles is turned on on the domain
  const configurableRolesEnabled = FeatureFlags.value('configurable_roles');

  return (
    <div className="rights-list-modal-footer">
      <div className="rights-list-modal-footer-links">
        {configurableRolesEnabled && (
          <a href="/admin/roles" target="_blank" rel="noopener noreferrer">
            {I18n.t('account.common.profile.manage_role_definitions')}{' '}
            <SocrataIcon name={IconName.External} />
          </a>
        )}
        <a href="/admin/users" target="_blank" rel="noopener noreferrer">
          {I18n.t('account.common.profile.manage_user_roles')} <SocrataIcon name={IconName.External} />
        </a>
      </div>
      <Button onClick={onClose}>{I18n.t('core.dialogs.close')}</Button>
    </div>
  );
};

/** Spinner for when rights are loading */
const renderSpinner = () => (
  <div className="rights-list-spinner-container">
    <div className="spinner-default spinner-large" />
  </div>
);

/**
 * Renders a modal that shows all of the rights that a given role has, and has links to go
 * edit the role definitions and the role that a user has.
 */
const UserProfileRoleRightsListModal: FunctionComponent<UserProfileRoleRightsListModalProps> = ({
  onClose,
  role
}) => {
  const dispatch = useDispatch();
  const rights = useSelector(getRightCategories);

  // fetch user role when the component mounts
  // this only gets called once
  useEffect(() => {
    dispatch(fetchRights());
  }, []);

  return (
    <Modal onDismiss={onClose}>
      {renderHeader(role, onClose)}
      <ModalContent>{rights ? renderRights(role, rights) : renderSpinner()}</ModalContent>
      {renderFooter(onClose)}
    </Modal>
  );
};

export default UserProfileRoleRightsListModal;
