import React, { useEffect, useState, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import I18n from 'common/i18n';
import Button, { SIZES } from 'common/components/Button';
import LocalizedRoleName from 'common/i18n/components/LocalizedRoleName';
import { currentUserHasRight } from 'common/current_user';
import { MANAGE_USERS, MANAGE_ROLE_DEFINITIONS } from 'common/rights';

import { getUserRole, getUser } from './selectors';
import { fetchRole } from './actions';
import UserProfileRoleRightsListModal from './UserProfileRoleRightsListModal';

/**
 * Shows an info icon if the current user has sufficient permissions
 * Indicates clicking will open a modal to show the list of rights for the profile user's current role.
 *
 * @param canViewRoleRights Does the current user have sufficient permissions to view role rights
 */
const renderViewRightsIcon = (canViewRoleRights: boolean) => {
  let response;

  // only users who have "manage_users" and "manage_role_definitons" can see this
  if (canViewRoleRights) {
    response = (
      <span
        className="profile-head-item socrata-icon-info"
        title={I18n.t('account.common.profile.what_can_this_role_do')}
      />
    );
  }

  return response;
};

/**
 * Renders the user's role with a button to open a modal to list the role's rights.
 */
const UserProfileRole: FunctionComponent = () => {
  const [showingRightsListModal, setShowingRightsListModal] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userRole = useSelector(getUserRole);
  const canManageUsers = currentUserHasRight(MANAGE_USERS);
  const canManageRoleDefinitions = currentUserHasRight(MANAGE_ROLE_DEFINITIONS);
  const canViewRoleRights = canManageUsers && canManageRoleDefinitions;

  // fetch user role when the component mounts
  useEffect(() => {
    const roleId = user?.roleId;
    if (!roleId) return; // user is either has no role or the logged in user can't see their role
    dispatch(fetchRole(roleId));
  }, []);

  // still fetching the role...
  if (!userRole) {
    return null;
  }

  return (
    <div className="user-content-info user-content-current_role">
      <div className="user-content-info-label">{I18n.t('account.common.profile.current_role')}</div>
      <div className="user-content-info-value">
        <span onClick={() => setShowingRightsListModal(canViewRoleRights)}>
          <LocalizedRoleName role={userRole} />
          {renderViewRightsIcon(canViewRoleRights)}
        </span>
      </div>

      {/* Show the modal if it's open */}
      {showingRightsListModal && (
        <UserProfileRoleRightsListModal onClose={() => setShowingRightsListModal(false)} role={userRole} />
      )}
    </div>
  );
};

export default UserProfileRole;
