import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ProfileState } from 'account/view/types';
import type Team from 'common/types/users/teams';
import ResultsTable from 'common/components/ResultsTable';
import { SORT_KEYS } from 'common/teams-api';
import { currentUserHasRight } from 'common/current_user';
import TeamsPager from './TeamsPager';
import { getTeamsProfileUrl } from 'account/view/utils';
import I18n from 'common/i18n';
import * as Actions from '../actions';
import * as Selectors from '../selectors';
import FeatureFlags from 'common/feature_flags';
import DomainRights from 'common/types/domainRights';

interface StateProps {
  loadingData: boolean;
  orderBy: string;
  sortDirection: string;
  teams: Team[];
  viewingOwnProfile: boolean;
}

interface DispatchProps {
  /* eslint @typescript-eslint/ban-types: "warn" */
  loadTeams: Function;
  /* eslint @typescript-eslint/ban-types: "warn" */
  onSort: Function;
}

type Props = StateProps & DispatchProps;

export class UserTeamsTable extends Component<Props, ProfileState> {
  componentDidMount(): void {
    const { loadTeams } = this.props;
    loadTeams();
  }

  // in the array of members, the first member is always an owner
  renderOwnersCell = (members: Team[] = [], { id }: { id: string }) => {
    return <div>{members[0] ? members[0].screenName : ''}</div>;
  };

  renderTeamNameCell = (screenName: string, { id }: { id: string }) => {
    if (currentUserHasRight(DomainRights.manage_teams)) {
      return (
        <div>
          <a href={getTeamsProfileUrl(id)}>{screenName}</a>
        </div>
      );
    } else {
      return <div>{screenName}</div>;
    }
  };

  renderMembersCell = (members = [], { id }: { id: string }) => {
    return <div>{members.length}</div>;
  };

  render() {
    const { loadingData, onSort, orderBy, sortDirection, teams, viewingOwnProfile } = this.props;
    const noResultsMessage = I18n.t('users.no_results');
    const shouldRender =
      FeatureFlags.value('enable_teams') &&
      (viewingOwnProfile || currentUserHasRight(DomainRights.manage_teams));

    if (!shouldRender || !teams) {
      return null;
    }

    return (
      <div id="teams-table">
        <h3 id="teams-table-title">Teams</h3>
        <ResultsTable data={teams} rowKey="id" loadingData={loadingData} noResultsMessage={noResultsMessage}>
          <ResultsTable.Column
            isActive={orderBy === SORT_KEYS.SCREEN_NAME}
            sortDirection={sortDirection}
            onSort={() => onSort(SORT_KEYS.SCREEN_NAME)}
            dataIndex="screenName"
            header={I18n.t('users.headers.team_name')}
          >
            {this.renderTeamNameCell}
          </ResultsTable.Column>
          <ResultsTable.Column dataIndex="description" header={I18n.t('users.headers.description')} />
          <ResultsTable.Column dataIndex="members" header={I18n.t('users.headers.owner')}>
            {this.renderOwnersCell}
          </ResultsTable.Column>
          <ResultsTable.Column dataIndex="members" header={I18n.t('users.headers.members')}>
            {this.renderMembersCell}
          </ResultsTable.Column>
        </ResultsTable>
        <div>
          {/* If there's not results, don't show the pager. The pager will say 'No results', which is already
                displayed on the page in this table */}
          {teams && teams.length > 0 && <TeamsPager />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ProfileState): StateProps => ({
  loadingData: Selectors.getTeamsLoadingData(state),
  orderBy: Selectors.getTeamsOrderBy(state),
  sortDirection: Selectors.getTeamsSortDirection(state),
  teams: Selectors.getTeams(state),
  viewingOwnProfile: Selectors.getViewingOwnProfile(state)
});

const mapDispatchToProps: DispatchProps = {
  loadTeams: Actions.loadTeams,
  onSort: Actions.sortTeamColumn
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTeamsTable);
