import isEmpty from 'lodash/isEmpty';

import { EmailVerificationStatus } from '@socrata/core-email-verification-api';
import { EmailVerificationStatusResponse } from '@socrata/core-email-verification-api';

import { FeatureFlags } from 'common/feature_flags';
import emailVerificationApi, { parseError } from 'common/core/email_verification';

/** query config for calling fetchEmailVerificationStatus */
export const queryConfig = {
  /** don't cache this */
  cacheTime: 0,
  /** don't retry if it fails */
  retry: false
};

/** Fake email verified status to use as a fallback in some cases */
const fakeVerifiedStatus: EmailVerificationStatusResponse = {
  status: EmailVerificationStatus.email_verified
};

/** Given the response from getEmailVerificationStatus, will return true if the user is verified else false */
export const checkIsUserVerified = (response?: EmailVerificationStatusResponse): boolean => {
  return !isEmpty(response) && response!.status === EmailVerificationStatus.email_verified;
};

export const fetchEmailVerificationStatus = async () => {
  return await emailVerificationApi.getEmailVerificationStatus({ userIdentifier: 'current' });
};

export default fetchEmailVerificationStatus;
