import React, { Component } from 'react';

import I18n from 'common/i18n/index';
import ResultsTable from 'common/components/ResultsTable';
import { AppTokenType } from './types';
import AppToken from './AppToken';
import { getResultsCountMessage } from '../../edit/components/DeveloperSettingsPane/helpers';

interface Props {
  appTokens: AppTokenType[];
  editControlComponent?: React.ComponentType;
  loadingData?: boolean;
  secretTokenComponent?: React.ComponentType;
  showTokens: boolean; // showing tokens only works when viewing your own profile!
  userUid?: string;
}

class AppTokensTable extends Component<Props> {
  renderSecretTokens = (userUid: string, secretTokenComponent: React.ComponentType) => {
    return (
      <ResultsTable.Column
        dataClassName="app-tokens-table__secret-token-column"
        cellProps={{ userUid, label: I18n.t('screens.profile.app_tokens.item.show_secret_link') }}
        cellComponent={secretTokenComponent}
        header={I18n.t('screens.profile.app_tokens.item.secret_token')}
        dataIndex="id"
      />
    );
  };

  renderEditControl = (editControlComponent: React.ComponentType) => {
    return (
      <ResultsTable.Column
        header={I18n.t('screens.profile.app_tokens.item.actions')}
        dataIndex="id"
        dataClassName="app-tokens-table__actions-column"
      >
        {editControlComponent}
      </ResultsTable.Column>
    );
  };

  renderActualTokens = () => {
    return (
      <ResultsTable.Column
        dataClassName="app-tokens-table__app-token-column"
        cellComponent={AppToken}
        header={I18n.t('screens.profile.app_tokens.item.app_token')}
        dataIndex="app_token"
      />
    );
  };

  render() {
    const { appTokens, editControlComponent, loadingData, showTokens, secretTokenComponent, userUid } =
      this.props;
    const resultsCountMessage = getResultsCountMessage(appTokens);
    return (
      <ResultsTable
        data={appTokens}
        loadingData={loadingData !== null ? loadingData : false}
        rowKey="id"
        noResultsMessage={I18n.t('controls.common.no_results')}
        id="app-tokens-table"
        tabIndex={0}
        aria-label={resultsCountMessage}
      >
        <ResultsTable.Column header={I18n.t('screens.profile.app_tokens.item.name')} dataIndex="name" />
        <ResultsTable.Column
          header={I18n.t('screens.profile.app_tokens.item.description')}
          dataIndex="description"
        />
        {showTokens && this.renderActualTokens()}
        {userUid && secretTokenComponent && this.renderSecretTokens(userUid, secretTokenComponent)}
        {editControlComponent && this.renderEditControl(editControlComponent)}
      </ResultsTable>
    );
  }
}

export default AppTokensTable;
