import { fetchJsonWithParsedError } from 'common/http';

export const getAppTokenError = (errorJson) => {
  if (/Unknown URL/.test(errorJson.message)) {
    return 'screens.profile.edit.app_tokens.core_error.invalid_url';
  } else if (/There already exists an application/.test(errorJson.message)) {
    return 'screens.profile.edit.app_tokens.core_error.already_exists';
  } else {
    return 'screens.profile.edit.app_tokens.core_error.null_error_code_html';
  }
};

const getAppTokensEndpoint = (userUid) => `/api/users/${userUid}/app_tokens.json`;
const getAppTokenEndpoint = (userUid, tokenId) => `/api/users/${userUid}/app_tokens/${tokenId}`;
const getAppTokenSecretEndpoint = (userUid, tokenId) =>
  `${getAppTokenEndpoint(userUid, tokenId)}?method=getSecret`;

export const fetchAppTokens = (userUid) => fetchJsonWithParsedError(getAppTokensEndpoint(userUid));
export const deleteAppToken = (userUid, tokenId) =>
  fetchJsonWithParsedError(getAppTokenEndpoint(userUid, tokenId), { method: 'delete' });
export const updateAppToken = (userUid, tokenId, appToken) =>
  fetchJsonWithParsedError(getAppTokenEndpoint(userUid, tokenId), {
    body: JSON.stringify(appToken),
    method: 'put'
  });
export const createAppToken = (userUid, appToken) =>
  fetchJsonWithParsedError(getAppTokensEndpoint(userUid), { body: JSON.stringify(appToken), method: 'post' });
export const fetchAppTokenSecret = (userUid, id) =>
  fetchJsonWithParsedError(getAppTokenSecretEndpoint(userUid, id));
