import { action } from 'typesafe-actions';

import type Team from 'common/types/users/teams';
import type { AppTokenType } from 'account/components/AppTokensTable/types';
import type { Role, RightsCategory } from '@socrata/core-roles-api';

/** FOR TEAMS **/
// Load teams
export const LOAD_TEAMS = 'LOAD_TEAMS';
export const LOAD_TEAMS_SUCCESS = 'LOAD_TEAMS_SUCCESS';
export const LOAD_TEAMS_FAILURE = 'LOAD_TEAMS_FAILURE';
export const loadTeams = () => ({ type: LOAD_TEAMS });
export const loadTeamsSuccess = (teams: Team, resultCount: number) => ({
  type: LOAD_TEAMS_SUCCESS,
  payload: { teams, resultCount }
});
export const loadTeamsFailure = (error: string) => ({
  type: LOAD_TEAMS_FAILURE,
  error: true,
  payload: { error }
});

// Search Teams and Navigation
export const TEAMS_SEARCH = 'TEAMS_SEARCH';
export const TEAMS_SEARCH_SUCCESS = 'TEAMS_SEARCH_SUCCESS';
export const TEAMS_SEARCH_FAILURE = 'TEAMS_SEARCH_FAILURE';
export const teamsSearch = (query?: string) => ({ type: TEAMS_SEARCH, payload: { query } });
export const teamsSearchSuccess = (teams: Team, resultCount: number) => ({
  type: TEAMS_SEARCH_SUCCESS,
  payload: { teams, resultCount }
});
export const teamsSearchFailure = (error: string) => ({
  type: TEAMS_SEARCH_FAILURE,
  error: true,
  payload: { error }
});

// Navigating through teams
export const GOTO_TEAM_PAGE = 'GOTO_TEAM_PAGE';
export const gotoTeamPage = (page: number) => ({ type: GOTO_TEAM_PAGE, payload: { page } });

// Sorting columns
export const SORT_TEAM_COLUMN = 'SORT_TEAM_COLUMN';
export const sortTeamColumn = (columnKey: string) => ({ type: SORT_TEAM_COLUMN, payload: { columnKey } });

/** FOR APP TOKENS **/
// Load app tokens
export const LOAD_APP_TOKENS = 'LOAD_APP_TOKENS';
export const LOAD_APP_TOKENS_SUCCESS = 'LOAD_APP_TOKENS_SUCCESS';
export const LOAD_APP_TOKENS_FAILURE = 'LOAD_APP_TOKENS_FAILURE';
export const loadAppTokens = () => ({ type: LOAD_APP_TOKENS });
export const loadAppTokensSuccess = (appTokens: AppTokenType) => ({
  type: LOAD_APP_TOKENS_SUCCESS,
  payload: { appTokens }
});
export const loadAppTokensFailure = (error: string) => ({
  type: LOAD_APP_TOKENS_FAILURE,
  error: true,
  payload: { error }
});

/** FOR ROLES */
export const FETCH_ROLE = 'FETCH_ROLE';
export const fetchRole = (roleId: number) => action(FETCH_ROLE, { roleId });
export type FetchRole = ReturnType<typeof fetchRole>;

export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
export const fetchRoleSuccess = (role: Role) => action(FETCH_ROLE_SUCCESS, { role });
export type FetchRoleSuccess = ReturnType<typeof fetchRoleSuccess>;

export const FETCH_ROLE_FAILURE = 'FETCH_ROLE_FAILURE';
export const fetchRoleFailure = (error: any) => action(FETCH_ROLE_FAILURE, { error });
export type FetchRoleFailure = ReturnType<typeof fetchRoleFailure>;

export const FETCH_RIGHTS = 'FETCH_RIGHTS';
export const fetchRights = () => action(FETCH_RIGHTS);
export type FetchRights = ReturnType<typeof fetchRights>;

export const FETCH_RIGHTS_SUCCESS = 'FETCH_RIGHTS_SUCCESS';
export const fetchRightsSuccess = (rights: RightsCategory[]) => action(FETCH_RIGHTS_SUCCESS, { rights });
export type FetchRightsSuccess = ReturnType<typeof fetchRightsSuccess>;

export const FETCH_RIGHTS_FAILURE = 'FETCH_RIGHTS_FAILURE';
export const fetchRightsFailure = (error: any) => action(FETCH_RIGHTS_FAILURE, { error });
export type FetchRightsFailure = ReturnType<typeof fetchRightsFailure>;
